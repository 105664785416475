'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.arrayMove = arrayMove;
exports.omit = omit;
exports.closest = closest;
exports.limit = limit;
exports.getElementMargin = getElementMargin;
exports.provideDisplayName = provideDisplayName;
function arrayMove(arr, previousIndex, newIndex) {
    var array = arr.slice(0);
    if (newIndex >= array.length) {
        var k = newIndex - array.length;
        while (k-- + 1) {
            array.push(undefined);
        }
    }
    array.splice(newIndex, 0, array.splice(previousIndex, 1)[0]);
    return array;
}

function omit(obj) {
    for (var _len = arguments.length, keysToOmit = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        keysToOmit[_key - 1] = arguments[_key];
    }

    return Object.keys(obj).reduce(function (acc, key) {
        if (keysToOmit.indexOf(key) === -1) acc[key] = obj[key];
        return acc;
    }, {});
}

var events = exports.events = {
    start: ['touchstart', 'mousedown'],
    move: ['touchmove', 'mousemove'],
    end: ['touchend', 'touchcancel', 'mouseup']
};

var vendorPrefix = exports.vendorPrefix = function () {
    if (typeof window === 'undefined' || typeof document === 'undefined') return ''; // server environment
    // fix for:
    //    https://bugzilla.mozilla.org/show_bug.cgi?id=548397
    //    window.getComputedStyle() returns null inside an iframe with display: none
    // in this case return an array with a fake mozilla style in it.
    var styles = window.getComputedStyle(document.documentElement, '') || ['-moz-hidden-iframe'];
    var pre = (Array.prototype.slice.call(styles).join('').match(/-(moz|webkit|ms)-/) || styles.OLink === '' && ['', 'o'])[1];

    switch (pre) {
        case 'ms':
            return 'ms';
        default:
            return pre && pre.length ? pre[0].toUpperCase() + pre.substr(1) : '';
    }
}();

function closest(el, fn) {
    while (el) {
        if (fn(el)) return el;
        el = el.parentNode;
    }
}

function limit(min, max, value) {
    if (value < min) {
        return min;
    }
    if (value > max) {
        return max;
    }
    return value;
}

function getCSSPixelValue(stringValue) {
    if (stringValue.substr(-2) === 'px') {
        return parseFloat(stringValue);
    }
    return 0;
}

function getElementMargin(element) {
    var style = window.getComputedStyle(element);

    return {
        top: getCSSPixelValue(style.marginTop),
        right: getCSSPixelValue(style.marginRight),
        bottom: getCSSPixelValue(style.marginBottom),
        left: getCSSPixelValue(style.marginLeft)
    };
}

function provideDisplayName(prefix, Component) {
    var componentName = Component.displayName || Component.name;

    return componentName ? prefix + '(' + componentName + ')' : prefix;
}