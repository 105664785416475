'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactcss = require('reactcss');

var _reactcss2 = _interopRequireDefault(_reactcss);

var _isString = require('lodash/isString');

var _isString2 = _interopRequireDefault(_isString);

var _Tab = require('./Tab');

var _Tab2 = _interopRequireDefault(_Tab);

var _Link = require('./Link');

var _Link2 = _interopRequireDefault(_Link);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

// var Ink = require('./Ink');

// var context = {
//   primaryColor: '#2196F3',
//   accentColor: '#E91E63',
//   theme: 'light'
// }

var Tabs = function (_React$Component) {
  _inherits(Tabs, _React$Component);

  function Tabs(props) {
    _classCallCheck(this, Tabs);

    var _this = _possibleConstructorReturn(this, Object.getPrototypeOf(Tabs).call(this));

    var selectedTab;
    if (props.selectedTab < (props.tabs && props.tabs.length)) {
      selectedTab = props.selectedTab;
    } else {
      selectedTab = 0;
    }

    _this.state = {
      selectedTab: selectedTab
    };

    _this.handleClick = _this.handleClick.bind(_this);
    _this.slide = _this.slide.bind(_this);
    return _this;
  }

  _createClass(Tabs, [{
    key: 'handleClick',
    value: function handleClick(tab) {
      if (this.props.onChange) {
        this.props.onChange(tab);
      }

      this.setState({
        selectedTab: tab
      });
    }
  }, {
    key: 'slide',
    value: function slide() {
      if (this.props.tabs.length) {
        var containerNode = this.refs.tabs.getDOMNode();
        var containerLeft = containerNode.scrollLeft;
        var containerRight = containerNode.offsetWidth + containerNode.scrollLeft;

        var selectedNode = this.refs['tab-' + this.state.selectedTab] && this.refs['tab-' + this.state.selectedTab].getDOMNode();
        var selectedLeft = selectedNode && selectedNode.getBoundingClientRect().left - containerNode.getBoundingClientRect().left + containerNode.scrollLeft;
        var selectedRight = selectedNode && selectedLeft + selectedNode.offsetWidth;

        // scroll right if tab is off screen
        if (selectedRight > containerRight) {
          containerNode.scrollLeft += selectedRight - containerRight;
        }

        // scroll left if tab is off screen
        if (selectedLeft < containerLeft) {
          containerNode.scrollLeft -= containerLeft - selectedLeft;
        }

        // slide the indicator
        var indicator = this.refs.indicator;
        indicator.style.left = selectedLeft + 'px';
        indicator.style.width = selectedNode.offsetWidth + 'px';
        indicator.style.height = '2px';
      }
    }
  }, {
    key: 'componentDidMount',
    value: function componentDidMount() {
      this.slide();
    }
  }, {
    key: 'componentWillReceiveProps',
    value: function componentWillReceiveProps(nextProps) {
      if (nextProps.selectedTab !== this.state.selectedTab) {
        this.setState({ selectedTab: nextProps.selectedTab });
      }
    }
  }, {
    key: 'componentWillUpdate',
    value: function componentWillUpdate(nextProps, nextState) {
      if (nextState.selectedTab >= (nextProps.tabs && nextProps.tabs.length)) {
        nextState.selectedTab = nextProps.tabs.length - 1;
      }
    }
  }, {
    key: 'componentDidUpdate',
    value: function componentDidUpdate() {
      this.slide();
    }
  }, {
    key: 'render',
    value: function render() {

      var styles = (0, _reactcss2.default)({
        'default': {
          tabs: {
            position: 'relative',
            background: this.props.background
          },
          tabWrap: {
            display: 'flex'
          },
          tab: {
            justifyContent: 'flex-start',
            minWidth: '68px',
            maxWidth: '240px'
          },
          Tab: {
            color: this.props.color,
            inactive: this.props.inactive,
            capitalize: this.props.capitalize
          },
          indicator: {
            height: '0',
            position: 'absolute',
            bottom: '0',
            left: '0',
            background: this.props.color,
            transition: 'all 200ms linear'
          }
        },
        'scrollable': {
          tabs: {
            overflowX: 'scroll'
          },
          tabWrap: {
            paddingLeft: '60px',
            justifyContent: 'flex-start',
            width: '400%'
          },
          tab: {
            width: 'auto'
          }
        },
        'align-justify': {
          tabWrap: {
            justifyContent: 'space-between'
          },
          tab: {
            width: 100 / this.props.tabs.length + '%'
          }
        },
        'align-left': {
          tabWrap: {
            paddingLeft: '60px',
            justifyContent: 'flex-start'
          },
          tab: {
            width: 'auto'
          }
        },
        'align-center': {
          tabWrap: {
            justifyContent: 'center'
          },
          tab: {
            width: 'auto'
          }
        }
      }, {
        'scrollable': this.props.width / this.props.tabs.length < 72
      }, this.props, this.state);

      var tabs = [];
      for (var i = 0; i < this.props.tabs.length; i++) {
        var tab = this.props.tabs[i];

        var label;
        var callback;
        var callbackValue;
        var newTab;
        if ((0, _isString2.default)(tab)) {
          label = tab;
          callback = null;
        } else {
          label = tab.label;
          callback = tab.onClick;
          callbackValue = tab.callbackValue;
          newTab = tab.newTab;
        }

        tabs.push(_react2.default.createElement(
          'div',
          { style: styles.tab, ref: 'tab-' + i, key: i },
          _react2.default.createElement(
            _Link2.default,
            { onClick: callback, callbackValue: callbackValue, newTab: newTab },
            _react2.default.createElement(
              _Tab2.default,
              { style: styles.Tab, tab: i, selected: this.state.selectedTab === i, selectable: tab.selectable, onClick: this.handleClick },
              label
            )
          )
        ));
      }

      return _react2.default.createElement(
        'div',
        { style: styles.tabs, ref: 'tabs' },
        _react2.default.createElement(
          'div',
          { style: styles.tabWrap, className: 'flexbox-fix' },
          tabs
        ),
        _react2.default.createElement('div', { style: styles.indicator, ref: 'indicator' })
      );
    }
  }]);

  return Tabs;
}(_react2.default.Component);

Tabs.defaultProps = {
  selectedTab: 0,
  background: 'transparent',
  color: '#fff'
};

exports.default = Tabs;