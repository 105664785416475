'use strict';

var base64url = module.exports;

base64url.unescape = function unescape (str) {
  return (str + '==='.slice((str.length + 3) % 4))
    .replace(/\-/g, '+')
    .replace(/_/g, '/');
};

base64url.escape = function escape (str) {
  return str.replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');
};

base64url.encode = function encode (str) {
  return this.escape(new Buffer(str).toString('base64'));
};

base64url.decode = function decode (str) {
  return new Buffer(this.unescape(str), 'base64').toString();
};
