'use strict';

Object.defineProperty(exports, '__esModule', {
  value: true
});

exports['default'] = function (value) {
  if (Array.isArray(value)) value = value.join(',');

  return value.match(/-webkit-|-moz-|-ms-/) !== null;
};

module.exports = exports['default'];