'use strict';

exports.__esModule = true;
exports['default'] = createTargetFactory;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { 'default': obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError('Cannot call a class as a function'); } }

var _invariant = require('invariant');

var _invariant2 = _interopRequireDefault(_invariant);

var _lodashIsPlainObject = require('lodash/isPlainObject');

var _lodashIsPlainObject2 = _interopRequireDefault(_lodashIsPlainObject);

var ALLOWED_SPEC_METHODS = ['canDrop', 'hover', 'drop'];

function createTargetFactory(spec) {
  Object.keys(spec).forEach(function (key) {
    _invariant2['default'](ALLOWED_SPEC_METHODS.indexOf(key) > -1, 'Expected the drop target specification to only have ' + 'some of the following keys: %s. ' + 'Instead received a specification with an unexpected "%s" key. ' + 'Read more: http://gaearon.github.io/react-dnd/docs-drop-target.html', ALLOWED_SPEC_METHODS.join(', '), key);
    _invariant2['default'](typeof spec[key] === 'function', 'Expected %s in the drop target specification to be a function. ' + 'Instead received a specification with %s: %s. ' + 'Read more: http://gaearon.github.io/react-dnd/docs-drop-target.html', key, key, spec[key]);
  });

  var Target = (function () {
    function Target(monitor) {
      _classCallCheck(this, Target);

      this.monitor = monitor;
      this.props = null;
      this.component = null;
    }

    Target.prototype.receiveProps = function receiveProps(props) {
      this.props = props;
    };

    Target.prototype.receiveMonitor = function receiveMonitor(monitor) {
      this.monitor = monitor;
    };

    Target.prototype.receiveComponent = function receiveComponent(component) {
      this.component = component;
    };

    Target.prototype.canDrop = function canDrop() {
      if (!spec.canDrop) {
        return true;
      }

      return spec.canDrop(this.props, this.monitor);
    };

    Target.prototype.hover = function hover() {
      if (!spec.hover) {
        return;
      }

      spec.hover(this.props, this.monitor, this.component);
    };

    Target.prototype.drop = function drop() {
      if (!spec.drop) {
        return;
      }

      var dropResult = spec.drop(this.props, this.monitor, this.component);
      if (process.env.NODE_ENV !== 'production') {
        _invariant2['default'](typeof dropResult === 'undefined' || _lodashIsPlainObject2['default'](dropResult), 'drop() must either return undefined, or an object that represents the drop result. ' + 'Instead received %s. ' + 'Read more: http://gaearon.github.io/react-dnd/docs-drop-target.html', dropResult);
      }
      return dropResult;
    };

    return Target;
  })();

  return function createTarget(monitor) {
    return new Target(monitor);
  };
}

module.exports = exports['default'];