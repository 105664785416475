"use strict";

exports.__esModule = true;
exports["default"] = getNextUniqueId;
var nextUniqueId = 0;

function getNextUniqueId() {
  return nextUniqueId++;
}

module.exports = exports["default"];