'use strict';

exports.__esModule = true;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { 'default': obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError('Cannot call a class as a function'); } }

var _invariant = require('invariant');

var _invariant2 = _interopRequireDefault(_invariant);

var _utilsMatchesType = require('./utils/matchesType');

var _utilsMatchesType2 = _interopRequireDefault(_utilsMatchesType);

var _lodashIsArray = require('lodash/isArray');

var _lodashIsArray2 = _interopRequireDefault(_lodashIsArray);

var _HandlerRegistry = require('./HandlerRegistry');

var _HandlerRegistry2 = _interopRequireDefault(_HandlerRegistry);

var _reducersDragOffset = require('./reducers/dragOffset');

var _reducersDirtyHandlerIds = require('./reducers/dirtyHandlerIds');

var DragDropMonitor = (function () {
  function DragDropMonitor(store) {
    _classCallCheck(this, DragDropMonitor);

    this.store = store;
    this.registry = new _HandlerRegistry2['default'](store);
  }

  DragDropMonitor.prototype.subscribeToStateChange = function subscribeToStateChange(listener) {
    var _this = this;

    var _ref = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

    var handlerIds = _ref.handlerIds;

    _invariant2['default'](typeof listener === 'function', 'listener must be a function.');
    _invariant2['default'](typeof handlerIds === 'undefined' || _lodashIsArray2['default'](handlerIds), 'handlerIds, when specified, must be an array of strings.');

    var prevStateId = this.store.getState().stateId;
    var handleChange = function handleChange() {
      var state = _this.store.getState();
      var currentStateId = state.stateId;
      try {
        var canSkipListener = currentStateId === prevStateId || currentStateId === prevStateId + 1 && !_reducersDirtyHandlerIds.areDirty(state.dirtyHandlerIds, handlerIds);

        if (!canSkipListener) {
          listener();
        }
      } finally {
        prevStateId = currentStateId;
      }
    };

    return this.store.subscribe(handleChange);
  };

  DragDropMonitor.prototype.subscribeToOffsetChange = function subscribeToOffsetChange(listener) {
    var _this2 = this;

    _invariant2['default'](typeof listener === 'function', 'listener must be a function.');

    var previousState = this.store.getState().dragOffset;
    var handleChange = function handleChange() {
      var nextState = _this2.store.getState().dragOffset;
      if (nextState === previousState) {
        return;
      }

      previousState = nextState;
      listener();
    };

    return this.store.subscribe(handleChange);
  };

  DragDropMonitor.prototype.canDragSource = function canDragSource(sourceId) {
    var source = this.registry.getSource(sourceId);
    _invariant2['default'](source, 'Expected to find a valid source.');

    if (this.isDragging()) {
      return false;
    }

    return source.canDrag(this, sourceId);
  };

  DragDropMonitor.prototype.canDropOnTarget = function canDropOnTarget(targetId) {
    var target = this.registry.getTarget(targetId);
    _invariant2['default'](target, 'Expected to find a valid target.');

    if (!this.isDragging() || this.didDrop()) {
      return false;
    }

    var targetType = this.registry.getTargetType(targetId);
    var draggedItemType = this.getItemType();
    return _utilsMatchesType2['default'](targetType, draggedItemType) && target.canDrop(this, targetId);
  };

  DragDropMonitor.prototype.isDragging = function isDragging() {
    return Boolean(this.getItemType());
  };

  DragDropMonitor.prototype.isDraggingSource = function isDraggingSource(sourceId) {
    var source = this.registry.getSource(sourceId, true);
    _invariant2['default'](source, 'Expected to find a valid source.');

    if (!this.isDragging() || !this.isSourcePublic()) {
      return false;
    }

    var sourceType = this.registry.getSourceType(sourceId);
    var draggedItemType = this.getItemType();
    if (sourceType !== draggedItemType) {
      return false;
    }

    return source.isDragging(this, sourceId);
  };

  DragDropMonitor.prototype.isOverTarget = function isOverTarget(targetId) {
    var _ref2 = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

    var _ref2$shallow = _ref2.shallow;
    var shallow = _ref2$shallow === undefined ? false : _ref2$shallow;

    if (!this.isDragging()) {
      return false;
    }

    var targetType = this.registry.getTargetType(targetId);
    var draggedItemType = this.getItemType();
    if (!_utilsMatchesType2['default'](targetType, draggedItemType)) {
      return false;
    }

    var targetIds = this.getTargetIds();
    if (!targetIds.length) {
      return false;
    }

    var index = targetIds.indexOf(targetId);
    if (shallow) {
      return index === targetIds.length - 1;
    } else {
      return index > -1;
    }
  };

  DragDropMonitor.prototype.getItemType = function getItemType() {
    return this.store.getState().dragOperation.itemType;
  };

  DragDropMonitor.prototype.getItem = function getItem() {
    return this.store.getState().dragOperation.item;
  };

  DragDropMonitor.prototype.getSourceId = function getSourceId() {
    return this.store.getState().dragOperation.sourceId;
  };

  DragDropMonitor.prototype.getTargetIds = function getTargetIds() {
    return this.store.getState().dragOperation.targetIds;
  };

  DragDropMonitor.prototype.getDropResult = function getDropResult() {
    return this.store.getState().dragOperation.dropResult;
  };

  DragDropMonitor.prototype.didDrop = function didDrop() {
    return this.store.getState().dragOperation.didDrop;
  };

  DragDropMonitor.prototype.isSourcePublic = function isSourcePublic() {
    return this.store.getState().dragOperation.isSourcePublic;
  };

  DragDropMonitor.prototype.getInitialClientOffset = function getInitialClientOffset() {
    return this.store.getState().dragOffset.initialClientOffset;
  };

  DragDropMonitor.prototype.getInitialSourceClientOffset = function getInitialSourceClientOffset() {
    return this.store.getState().dragOffset.initialSourceClientOffset;
  };

  DragDropMonitor.prototype.getClientOffset = function getClientOffset() {
    return this.store.getState().dragOffset.clientOffset;
  };

  DragDropMonitor.prototype.getSourceClientOffset = function getSourceClientOffset() {
    return _reducersDragOffset.getSourceClientOffset(this.store.getState().dragOffset);
  };

  DragDropMonitor.prototype.getDifferenceFromInitialOffset = function getDifferenceFromInitialOffset() {
    return _reducersDragOffset.getDifferenceFromInitialOffset(this.store.getState().dragOffset);
  };

  return DragDropMonitor;
})();

exports['default'] = DragDropMonitor;
module.exports = exports['default'];